import React from "react";
import { useTranslation } from "react-i18next";
import FloorplanDetail from "../../components/floorplan";
import Line from "../../components/line";
import "./styles.css";

const Floorplans = () => {
  const { t } = useTranslation();

  return(
    <div className="column content-container">
      <h1 className="text-title">{t("floorplans.title")}</h1>
      <p className="text-basic">{t("floorplans.description")}</p>
      <FloorplanDetail number={1} />
      <Line />
      <FloorplanDetail number={2} />
      <Line />
      <FloorplanDetail number={3} />
      <Line />
      <FloorplanDetail number={4} />
    </div>
  );
};

export default Floorplans;