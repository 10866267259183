import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import Button from "../button";
import "./styles.css";

const ContactUs = ({ active, handleClose }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const onClose = (e) => {
    if (e.target.id === "overlay") {
      closeModal();
    }
  };

  const closeModal = () => {
    setIsActive(false);
    handleClose();
    setName("");
    setEmail("");
    setPhone("");
  };

  const handleSubmit = () => {
    setIsSending(true)
    const data = {
      name,
      email,
      phone
    }
    fetch("https://killpop-api.glitch.me/sendMailEcolanding", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setIsSending(false)
        swal({
          title: "Perfect!",
          text: "We have received your data and will get in touch soon :)",
          icon: "success",
          button: {
            text: "Ok",
          },
        });
      })
      .catch(function (error) {
        swal("Something went wrong", "Please, try again", "error");
        setIsSending(false)
      });
  }

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <div
      id="overlay"
      className={`overlay ${isActive ? "active" : ""}`}
      onClick={onClose}
    >
      <div className={`modal-container ${isActive ? "in" : "out"}`}>
        <h2 className="text-title-2 contact-title">{t("contact.title")}</h2>
        <label className="contact-label text-basic">
          Name{" "}
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
        </label>

        <label className="contact-label text-basic">
          Phone number{" "}
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></input>
        </label>

        <label className="contact-label text-basic">
          Email{" "}
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </label>

        <div className="contact-btns row">
          <Button size="medium" variant="transparent" handleClick={closeModal}>
            Cancel
          </Button>
          <Button size="medium" loading={isSending} handleClick={handleSubmit}>Send</Button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
