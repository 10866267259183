import React from "react";
import { useTranslation } from "react-i18next";
import Article from "../../components/article";
import Line from "../../components/line";
import "./styles.css";

const Media = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container">
      <p className="text-basic media-title">{t("media.header")}</p>
      <div className="row media-articles-container">
        <Article
          type="main"
          title="South Florida real estate projects in the pipeline for the week of March 18"
          img="https://media.bizj.us/view/img/12211552/braman-2.png"
          url="https://www.bizjournals.com/southflorida/news/2022/03/21/in-the-pipeline-03-18-2022.html"
          author="By The Business Journals"
          description="Mount Sinai Medical Center revealed plans for a $250 million cancer center, with a large chunk of funding coming from billionaires Norman and Irma Braman."
        />

        <div className="responsive-line">
          <Line />
        </div>

        <Line type="vertical" />
        <div className="column media-articles-secondary">
          <Article
            type="secondary"
            title="Cardone Capital buys apartment complex on Miami River"
            img="https://media.bizj.us/view/img/12227507/10x-miami-river*750xx2000-1125-0-104.jpg"
            author="By The Business Journals"
            url="https://www.bizjournals.com/southflorida/news/2022/03/29/10x-miami-river-acquired.html"
            description="Cardone Capital, led by 10X author and business advice guru Grant Cardone, acquired the Waterline apartments on the Miami River."
          />
          <Line />
          <Article
            type="secondary"
            title="Harbor Group International Acquires Iconic 816-Unit ParkLine Miami Luxury Highrise Apartment Community in Downtown Miami"
            img="https://apollostore.blob.core.windows.net/multifamilybiz/News/ParkLine_Miami_10087.jpeg"
            author="By MultifamilyBiz"
            url="https://www.multifamilybiz.com/news/10087/harbor_group_international_acquires_iconic_816unit"
            description="Affiliates of Harbor Group International, a privately owned international real estate investment and management firm, announced their acquisition of ParkLine Miami. ParkLine Miami is an iconic 816-unit, luxury apartment community in Downtown Miami developed by Florida's oldest and largest real estate and infrastructure development company, Florida East Coast Industries."
          />
          <Line />
          <Article
            type="secondary"
            url="https://www.miamiherald.com/news/business/real-estate-news/article260266405.html"
            title="Interest rates are rising, but that won’t cool Miami’s hot real estate market. Here’s why"
            img="https://images.unsplash.com/photo-1460317442991-0ec209397118?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            author="By Miami Herald"
            description="Many expected the Federal Reserve’s interest rate hike in March — the first increase since December 2018 — to be a ray of hope for those looking to buy in Miami’s competitive residential real estate market."
          />

        </div>
      </div>
    </div>
  );
};

export default Media;
