import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../button";
import ContactUs from "../contactUs";
import "./styles.css";
import img1 from "../../assets/img/A.png";
import img2 from "../../assets/img/B.png";
import img3 from "../../assets/img/C.png";
import img4 from "../../assets/img/D.png";

const FloorplanDetail = ({ number }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleContactClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  let image;
  if (number === 1) {
    image = img1;
  }
  if (number === 2) {
    image = img2;
  }
  if (number === 3) {
    image = img3;
  }
  if (number === 4) {
    image = img4;
  }

  return (
    <section className="column floorplan-section">
      <ContactUs active={isModalOpen} handleClose={handleClose} />
      <h2 className="text-title-2">{t(`floorplans.title-${number}`)}</h2>
      <p className="text-basic-bold">{t(`floorplans.description-${number}`)}</p>
      <div className="row floorplan-details">
        <figure>
          <img src={image} alt="floorplan" className="floorplan-img"></img>
        </figure>
        <div className="column floorplan-info">
          <div className="box"></div>
          <p className="floorplan-info-title">Inside the apartment</p>
          <ul className="floorplan-info-list">
            <li className="text-basic">Modern Architecture</li>
            <li className="text-basic">Assigned Parking per bedroom</li>
            <li className="text-basic">
              Electric Conduit in every Parking Space
            </li>
            <li className="text-basic">High Impact Windows</li>
            <li className="text-basic">Top Bathroom and Kitchen Finishes</li>
            <li className="text-basic">Top Carpentry and Cabinets</li>
            <li className="text-basic">Full Kitchen Appliances</li>
            <li className="text-basic">Washers and Dryers in every unit</li>
            <li className="text-basic">9 Foot ceilings</li>
            <li className="text-basic">Floor to Ceiling Sliding Glass Doors</li>
            <li className="text-basic">High Quality Floors</li>
            <li className="text-basic">Energy Efficient A/C units</li>
          </ul>
          <Button
            size="medium"
            variant="transparent"
            handleClick={handleContactClick}
          >
            Request more information
          </Button>
        </div>
      </div>
    </section>
  );
};

export default FloorplanDetail;
