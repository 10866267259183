import React from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import architecture from "../../assets/icons/architecture.svg";
import poolDeck from "../../assets/icons/pool.svg";
import kitchen from "../../assets/icons/kitchen.svg";
import elevators from "../../assets/icons/elevators.svg";
import lobby from "../../assets/icons/lobby.svg";
import publicBathroom from "../../assets/icons/publicBathroom.svg";
import ceiling from "../../assets/icons/ceiling.svg";
import swim from "../../assets/icons/swim.svg";
import access from "../../assets/icons/access.svg";
import children from "../../assets/icons/children.svg";
import doors from "../../assets/icons/doors.svg";
import floors from "../../assets/icons/floors.svg";
import parking from "../../assets/icons/parking.svg";
import electric from "../../assets/icons/electric.svg";
import windows from "../../assets/icons/windows.svg";
import storage from "../../assets/icons/storage.svg";
import gym from "../../assets/icons/gym.svg";
import cabinets from "../../assets/icons/cabinets.svg";

const Amenities = () => {
  const { t } = useTranslation();

  return (
    <div className="column content-container">
      <h1 className="text-title">{t("amenities.title")}</h1>
      <p className="text-basic">{t("amenities.description")}</p>
      <div className="amenities-container row">
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={architecture}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.modernArq")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={lobby}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.lobby")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={access}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.accesControl")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={parking}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.parking")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={electric}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.electricCond")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={swim}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.pool")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={poolDeck}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.poolDeck")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={publicBathroom}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.bathroom")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={children}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.playArea")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={gym}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.gym")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={storage}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.storageArea")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={elevators}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.elevators")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={windows}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.windows")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={publicBathroom}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.finishes")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={cabinets}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.carpentry")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={kitchen}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.appliances")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={ceiling}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.ceiling")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={doors}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.glassDoors")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={floors}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.floors")}</p>
        </div>
        <div className="row amenity">
          <div className="amenity-icon-container">
            <img
              src={electric}
              alt="logo"
              className="amenity-icon"
            ></img>
          </div>
          <p className="amenity-text">{t("amenities.acUnits")}</p>
        </div>
      </div>
    </div>
  );
};

export default Amenities;
