import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Button from "../../components/button";
import "./styles.css";

const Investor = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setError(false);
    }
  };

  const handleDownload = () => {
    if (isChecked) {
      window.location.href = "https://killcloud.nyc3.cdn.digitaloceanspaces.com/assets/summary.pdf" 
    } else {
      setError(true);
    }
  };

  return (
    <div className="content-container">
      <h1 className="text-title investment-title">{t("investment.title")}</h1>
      <div className="row investment-content">
        <div className="column investment-text">
          <p className="text-basic">
            <Trans i18nKey="investment.text"></Trans>
          </p>
          <div className="row investment-check">
            <input
              type="checkbox"
              name="check"
              id="check"
              onChange={handleChange}
              checked={isChecked}
              className={`${error ? "error" : ""}`}
            />
            <label
              className={`investment-checkbox ${error ? "error" : ""}`}
              htmlFor="check"
            >
              {t("investment.check")}
            </label>
          </div>
          <Button handleClick={handleDownload} className="investment-btn">
            {t("investment.download")}
          </Button>
        </div>
        <div className="column investment-imgs">
          <figure>
            <img
              src="https://images.unsplash.com/photo-1460317442991-0ec209397118?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt="logo"
              className="investment-img"
              width="242"
            ></img>
          </figure>
          <figure>
            <img
              src="https://images.unsplash.com/photo-1560520653-9e0e4c89eb11?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1073&q=80"
              alt="logo"
              className="investment-img"
              width="242"
            ></img>
          </figure>
          <figure>
            <img
              src="https://images.unsplash.com/photo-1618044733300-9472054094ee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
              alt="logo"
              className="investment-img"
              width="242"
            ></img>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default Investor;
