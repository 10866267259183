import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "../button";
import logo from "../../assets/img/logo.png";
import ContactUs from "../contactUs";
import "./styles.css";
import { useTranslation } from "react-i18next";

const animationCS = (isActive) => {
  if (isActive === null) {
    return "";
  } else if (isActive) {
    return "in";
  } else if (!isActive) {
    return "out";
  }
};

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const [mobileMenuActive, setMobileMenuActive] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();

  const isHome = location.pathname === "/";

  const handleContactClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleClick = (e) => {
    setMobileMenuActive(!mobileMenuActive);
  };

  useEffect(() => {
    setMobileMenuActive(false);
  }, [location]);

  return (
    <div className={`column header ${isHome ? "home" : ""}`}>
      <NavLink to="/">
        <figure>
          <img src={logo} alt="logo" className="navbar-logo"></img>
        </figure>
      </NavLink>

      <ContactUs active={isModalOpen} handleClose={handleClose} />
      
      <div className={`navbar row ${animationCS(mobileMenuActive)}`}>
        <div className="row menu">
          <NavLink to="/" className="mobile-menu-logo">
            <figure>
              <img src={logo} alt="logo" className="mobile-menu-logo"></img>
            </figure>
          </NavLink>

          <p className="footer-copyright-mobile">
            2021 Copyright © Ecostonegroup
          </p>

          <div className="link-container tenant">
            <p className="menu-link disable-select">
              {t("navbar.tenantPortal")}
            </p>
            <div className="triangle"></div>
            <div className="column nav-dropdown-menu">
              <NavLink className="dropdown-link" to="/floorplans">
                {t("navbar.floorplans")}
              </NavLink>
              <NavLink className="dropdown-link" to="/unit-mix">
                {t("navbar.unitMix")}
              </NavLink>
              <NavLink className="dropdown-link" to="/amenities">
                {t("navbar.amenities")}
              </NavLink>
            </div>
          </div>

          <NavLink className="menu-link" to="/investor">
            {t("navbar.investor")}
          </NavLink>

          <NavLink className="menu-link" to="/media">
            {t("navbar.media")}
          </NavLink>

          {/* <div className="link-container">
            <p className="menu-link disable-select lang">
              {t("navbar.language")}
            </p>
            <div className="triangle"></div>
            <div className="column nav-dropdown-menu">
              <p
                className="dropdown-link"
                onClick={() => i18n.changeLanguage("es")}
              >
                {t("navbar.spanish")}
              </p>
              <p
                className="dropdown-link"
                onClick={() => i18n.changeLanguage("en")}
              >
                {t("navbar.english")}
              </p>
            </div>
          </div> */}
        </div>
        <Button
          className="nav-btn"
          size="medium"
          handleClick={handleContactClick}
        >
          {t("navbar.getInTouch")}
        </Button>
      </div>
      {isHome && (
        <div className="home-top-section column">
          <p className="home-top-intro">{t("home.newHome")}</p>
          <h1 className="home-top-title">Ecolanding Tower</h1>
          <div className="home-text-container">
            <p className="home-top-text">{t("home.text")}</p>
          </div>
        </div>
      )}
      <div
        onClick={handleClick}
        className={`hamburger-menu ${mobileMenuActive ? "active" : ""}`}
      >
        <div className={`bar b1 ${animationCS(mobileMenuActive)}`}></div>
        <div className={`bar b2 ${animationCS(mobileMenuActive)}`}></div>
        <div className={`bar b3 ${animationCS(mobileMenuActive)}`}></div>
      </div>
    </div>
  );
};

export default Navbar;
