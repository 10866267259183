import React from "react";
import logo from "../../assets/img/logo.png";
import phone from "../../assets/img/phone.png";
import email from "../../assets/img/email.png";
import home from "../../assets/img/home.png";
import "./styles.css";

const Footer = () => {
  return (
    <footer className="column">
      <div className="row footer-top">
        <figure>
          <img src={logo} alt="logo" className="footer-logo"></img>
        </figure>
        <div className="column footer-info">
          <div className="row footer-info-row">
            <figure>
              <img src={phone} alt="logo" className="footer-icon"></img>
            </figure>
            <p className="footer-info-text">786 464 1740 | Fax: 786 464 1766</p>
          </div>

          <div className="row footer-info-row">
            <figure>
              <img src={email} alt="logo" className="footer-icon"></img>
            </figure>
            <p className="footer-info-text">info@ecostonegroup.com</p>
          </div>

          <div className="row footer-info-row">
            <figure>
              <img src={home} alt="logo" className="footer-icon"></img>
            </figure>
            <p className="footer-info-text">
              175 SW 7th st, Suite 1409 Miami, FL, 33130
            </p>
          </div>
        </div>
      </div>
      <p className="footer-copyright">
        2021 Copyright © Ecostonegroup. | Terms and Conditions
      </p>
    </footer>
  );
};

export default Footer;
