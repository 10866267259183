import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";

const UnitMix = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container">
      <h1 className="text-title unit-mix-title">{t("unitMix.title")}</h1>
      <p className="text-basic">{t("unitMix.description")}</p>
      <div className="unit-mix-table">
        <div className="grid-header border-radius-left">
          <p className="grid-title">{t("unitMix.title")}</p>
        </div>
        <div className="grid-header">
          <p className="grid-title">{t("unitMix.number")}</p>
        </div>
        <div className="grid-header">
          <p className="grid-title">{t("unitMix.squareFeet")}</p>
        </div>
        <div className="grid-header border-radius-right">
          <p className="grid-title">{t("unitMix.total")}</p>
        </div>

        {/* Row 1 */}
        <div className="grid-element bottom-line">
          <p className="grid-text">1 Bedroom / 1 Bathroom</p>
        </div>
        <div className="grid-element bottom-line">
          <p className="grid-text">52</p>
        </div>
        <div className="grid-element bottom-line">
          <p className="grid-text">600-850</p>
        </div>
        <div className="grid-element bottom-line">
          <p className="grid-text">42%</p>
        </div>

        {/* Row 2 */}
        <div className="grid-element bottom-line">
          <p className="grid-text">1 Bedroom + Den / 1 Bathroom</p>
        </div>
        <div className="grid-element bottom-line">
          <p className="grid-text">10</p>
        </div>
        <div className="grid-element bottom-line">
          <p className="grid-text">600-850</p>
        </div>
        <div className="grid-element bottom-line">
          <p className="grid-text">8%</p>
        </div>

        {/* Row 3 */}
        <div className="grid-element">
          <p className="grid-text">2 Bedrooms / 2 Bathrooms</p>
        </div>
        <div className="grid-element">
          <p className="grid-text">63</p>
        </div>
        <div className="grid-element">
          <p className="grid-text">840-900</p>
        </div>
        <div className="grid-element">
          <p className="grid-text">50%</p>
        </div>

        {/* Footer */}
        <div className="grid-footer border-radius-left">
          <p className="grid-text-footer">TOTAL / AVERAGE</p>
        </div>
        <div className="grid-footer">
          <p className="grid-text-footer">125</p>
        </div>
        <div className="grid-footer">
          <p className="grid-text-footer">773</p>
        </div>
        <div className="grid-footer border-radius-right">
          <p className="grid-text-footer">100%</p>
        </div>
      </div>
    </div>
  );
};

export default UnitMix;
