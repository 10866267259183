import React from "react";
import "./styles.css";

const Article = ({ type, img, title, description, author, url = "#" }) => {
  const direction = type === "main" ? "column" : "row";

  return (
    <div className={`${direction} media-article-${type}`}>
      <figure>
        <img src={img} alt="logo" className={`article-${type}-img`}></img>
      </figure>
      <div className="column">
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={`text-title-2 ${type}-article-title`}
        >
          {title}
        </a>
        {type === "main" && <p className="text-basic">{description}</p>}
        <p className="article-author">{author}</p>
      </div>
    </div>
  );
};

export default Article;
