import React, { useEffect, useState } from "react";
import "./styles.css";

const Button = ({
  children,
  handleClick,
  variant,
  className,
  size,
  loading = false,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(loading);
  }, [loading]);

  return (
    <button
      className={`btn ${size} ${className} ${variant}`}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {loading ? "Loading..." : children}
    </button>
  );
};

export default Button;
