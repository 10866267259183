import React from "react";
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Investor from "./views/investor/";
import Home from "./views/home/";
import Media from "./views/media/";
import Navbar from "./components/navbar/";
import Footer from "./components/footer/";
import "./index.css";
import UnitMix from "./views/unit-mix";
import Amenities from "./views/amenities";
import Floorplans from "./views/floorplans";

function App() {
  return (
    <Router>
      <div className="app">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/investor" element={<Investor />} />
            <Route path="/media" element={<Media />} />
            <Route path="/unit-mix" element={<UnitMix />} />
            <Route path="/amenities" element={<Amenities />} />
            <Route path="/floorplans" element={<Floorplans />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

const NotFound = () => (
  <div className="soon">
    <h1>Page not found :(</h1>
  </div>
);

export default function WrappedApp() {
  return (
    <Suspense fallback="">
      <App />
    </Suspense>
  );
}
