import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Line from "../../components/line";
import "./styles.css";

import pool from "../../assets/img/icons/pool.svg";
import gym from "../../assets/img/icons/gym.svg";
import storage from "../../assets/img/icons/storage.svg";
import media from "../../assets/img/icons/media.svg";
import gym2 from "../../assets/img/gym.png";
import pool2 from "../../assets/img/pool.png";
import media2 from "../../assets/img/media.png";
import storage2 from "../../assets/img/storage.png";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="home-container">
      <section className="content-container column home-design">
        <h1 className="home-title">{t("home.design")}</h1>
        <p className="text-basic">{t("home.designText")}</p>
        <div className="row home-amenities">
          <div className="home-amenities-icon-container column">
            <figure>
              <img src={pool} alt="pool" className="home-icon"></img>
            </figure>
            <img src={pool2} alt="gym" className="img-after"></img>
            <p className="home-icon-text">{t("home.pool")}</p>
          </div>
          <div className="home-amenities-icon-container column">
            <figure>
              <img src={gym} alt="gym" className="home-icon"></img>
            </figure>
            <img src={gym2} alt="gym" className="img-after"></img>
            <p className="home-icon-text">{t("home.gym")}</p>
          </div>
          <div className="home-amenities-icon-container column">
            <figure>
              <img src={storage} alt="storage" className="home-icon"></img>
            </figure>
            <img src={storage2} alt="gym" className="img-after"></img>
            <p className="home-icon-text">{t("home.storage")}</p>
          </div>
          <div className="home-amenities-icon-container column">
            <figure>
              <img src={media} alt="media" className="home-icon"></img>
            </figure>
            <img src={media2} alt="gym" className="img-after"></img>
            <p className="home-icon-text">{t("home.media")}</p>
          </div>
        </div>
        <NavLink className="text-basic-bold" to="/amenities">
          {t("home.moreAmenities")}
        </NavLink>
      </section>

      <section className="home-location">
        <div className="content-container">
          <h1 className="home-title">{t("home.location")}</h1>
          <p className="text-basic">{t("home.locationText")}</p>
          <div>
            <iframe
              title="google map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.341796661619!2d-80.22405928541363!3d25.792295613629317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b6dac5d9e24d%3A0xc88ae71c1609a021!2s1601%20NW%2018th%20St%2C%20Miami%2C%20FL%2033125%2C%20EE.%20UU.!5e0!3m2!1ses!2sch!4v1650359782538!5m2!1ses!2sch"
              className="location-img"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="line-container">
          <Line />
        </div>
      </section>
    </div>
  );
};

export default Home;
